import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Header, HeaderProps } from "../../molecules/header/header";
import { Sidebar, SidebarProps } from "../../molecules/sidebar/sidebar";
import { SidebarHeader } from "../../molecules/sidebar-header/sidebar-header";
import { MenuEntryInterface } from "../../types/menu-type";
import { type Log, LogDrawer } from "../log-drawer/log-drawer";

const SIDEBAR_WIDTH = 266;

const MainStage = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
  height: "100vh",
  overflow: "auto",
  ml: { md: `-${SIDEBAR_WIDTH}px` },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export interface DefaultOverlayPartialAppState {
  /** is sidebar open */
  isSidebarOpen: boolean;
}
export type DefaultOverlayEventNames =
  | "overlay:logout"
  | "overlay:toggle-sidebar"
  | "overlay:click-logo"
  | "overlay:show-logs";

export type DefaultOverlayProps = {
  /** props for the header */
  header: HeaderProps;
  /** Is sidebar open */
  isSidebarOpen: DefaultOverlayPartialAppState["isSidebarOpen"];
  /** Sidebar menu entries */
  menuEntries: MenuEntryInterface[];
  /** title and entries of the right side navigation, if undefined the sidebar will not show  */
  inPageNavigationSidebarProps?: {
    title: string;
    entries: { title: string; id: string }[];
  };
  logs?: Log[];
} & Pick<SidebarProps, "hasSearchFunctionality" | "searchFunction">;

export const initialDefaultOverlayState = {
  isSidebarOpen: false,
} satisfies DefaultOverlayPartialAppState;

/** Default Overlay with header, sidebar, and Outlet (react-router) */
export const DefaultOverlay: PimoReactComponent<
  DefaultOverlayProps,
  DefaultOverlayEventNames
> = ({
  fireEvent,
  hasSearchFunctionality,
  header,
  inPageNavigationSidebarProps,
  isSidebarOpen,
  logs,
  menuEntries,
  searchFunction,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(isSidebarOpen);
  const prevLocation = useRef(location);
  const hasMounted = useRef(false);

  /**
   * Manage sidebar visibility:
   * - Close sidebar on route change (only for mobile).
   */
  useEffect(() => {
    if (!hasMounted.current) {
      if (isMobile) {
        setSidebarVisible(true);
      }
      hasMounted.current = true;
    } else {
      if (isMobile && prevLocation.current !== location) {
        setSidebarVisible(false);
      }
    }

    prevLocation.current = location;
  }, [location, isMobile]);

  const handleSidebarToggle = () => {
    setSidebarVisible((prev) => !prev);
    fireEvent?.("overlay:toggle-sidebar");
  };

  return (
    <div data-testid="DefaultOverlay">
      <Box
        sx={{
          display: "flex",
          mt: `env(safe-area-inset-top) !important`,
        }}
      >
        <CssBaseline />
        <Header
          {...header}
          handleSidebarToggle={handleSidebarToggle}
          handleLogoClick={() => fireEvent?.("overlay:click-logo")}
          onLogsShow={() => {
            fireEvent?.("overlay:show-logs");
            setOpen(!open);
          }}
        />
        <Box
          component="nav"
          sx={{
            width: {
              xs: 0,
              sm: isSidebarVisible ? SIDEBAR_WIDTH : 0,
            },
            flexShrink: { xs: 0 },
          }}
          aria-label="sidebar menu"
        >
          <Sidebar
            entries={menuEntries}
            hasSearchFunctionality={hasSearchFunctionality}
            searchFunction={searchFunction}
            sidebarOpen={isSidebarVisible}
            width={SIDEBAR_WIDTH}
          />
        </Box>

        <MainStage>
          <SidebarHeader />
          <Outlet />
        </MainStage>
        {inPageNavigationSidebarProps && (
          <Box
            sx={{
              height: "100vh",
              minWidth: 300,
              maxWidth: 300,
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
              "@media print": {
                display: "none",
              },
            }}
          >
            <SidebarHeader />
            <Typography sx={{ fontWeight: 500, fontSize: "1.2rem", mb: 1 }}>
              {inPageNavigationSidebarProps.title}
            </Typography>
            <List
              sx={{
                borderLeft: "3px solid",
                borderColor: theme.palette.primary.main,
              }}
            >
              {inPageNavigationSidebarProps.entries.map((entry) => (
                <ListItem
                  onClick={() => {
                    const section = document.getElementById(entry.id);

                    if (section) {
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  key={entry.id}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary={entry.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <LogDrawer logs={logs} open={open} setOpen={setOpen} />
      </Box>
    </div>
  );
};
